import React, { useEffect } from 'react'
import Menu from './Menu'
import ScheduleOrderForm from './ScheduleOrderForm'
import { useNavigate } from 'react-router-dom'
import { useSelector } from '../../../state/store'
import {
  CreateCampaignOrderFooterStyled,
  ScheduleOrderFormContainer,
  ScheduleOrderMenuContainer,
  ScheduleOrderStepContainer,
} from './styles'
import ScheduleOrderReview from './ScheduleOrderReview'
import PlaceDataForm from './ScheduleOrderForm/PlaceDataForm'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import WhatsAppProfilePhoto from '../../../assets/WhatsAppProfilePhoto.png'

const ScheduleOrderStep = () => {
  const navigate = useNavigate()
  const campaign = useSelector((state) => state.createCampaignOrder.campaign)
  const createOrderFormStep = useSelector(
    (state) => state.createCampaignOrder.createOrderFormStep
  )
  const isVoucherCampaign = useSelector(
    (state) => state.createCampaignOrder.isVoucherCampaign
  )

  useEffect(() => {
    if (!isVoucherCampaign && !campaign) navigate('/campaign/order')
  }, [])

  if (!isVoucherCampaign && !campaign) return <></>

  return (
    <ScheduleOrderStepContainer container>
      <ScheduleOrderMenuContainer item xs={12} md={4} lg={3}>
        <CreateCampaignOrderFooterStyled />
        <Menu />
      </ScheduleOrderMenuContainer>
      <ScheduleOrderFormContainer item xs={12} md={8} lg={9}>
        {createOrderFormStep === 'place' && <PlaceDataForm />}
        {createOrderFormStep === 'form' && <ScheduleOrderForm />}
        {createOrderFormStep === 'review' && <ScheduleOrderReview />}
        <FloatingWhatsApp
          phoneNumber="5511933249548"
          accountName="Atendimento Vacinas.net"
          avatar={WhatsAppProfilePhoto}
          statusMessage="Disponível de segunda a sábado"
          chatMessage={`Olá!
Como podemos ajudar?`}
          placeholder="Digite sua mensagem..."
          messageDelay={1}
          style={{ height: 'unset' }}
        />
      </ScheduleOrderFormContainer>
      <style>
        {`
          @media screen and (max-width: 960px) {
            .floating-whatsapp-button {
              right: unset;
              bottom: 1rem;
            }
          }
        `}
      </style>
    </ScheduleOrderStepContainer>
  )
}

export default ScheduleOrderStep
