import React from 'react'
import { useSelector } from '../../../../state/store'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import {
  calculateGivenVouchers,
  calculateUsedVouchersAmount,
} from '../../utils'
import LocalActivityIcon from '@mui/icons-material/LocalActivity'
import { AvailableVoucherContainer, UsedVouchersContainer } from './styles'
import { VaccinesOutlined } from '@mui/icons-material'

const Menu = () => {
  const campaign =
    useSelector((state) => state.createCampaignOrder.campaign) || {}
  const isVoucherCampaign = useSelector(
    (state) => state.createCampaignOrder.isVoucherCampaign
  )
  const voucherCampaignData = useSelector(
    (state) => state.createCampaignOrder.voucherCampaignData
  )

  if (!isVoucherCampaign && !campaign) return <></>

  const usedVouchers = calculateUsedVouchersAmount(campaign)
  const givenVouchers = calculateGivenVouchers(campaign)

  return (
    <Grid
      container
      gap={2}
      paddingLeft={2.5}
      paddingRight={2.5}
      flexDirection={'column'}
      flexWrap="unset"
    >
      <Grid
        item
        xs={12}
        gap={1}
        display="flex"
        flexDirection="column"
        style={{ flexBasis: 0 }}
      >
        <Typography color="primary" variant="body1">
          Campanha
        </Typography>
        <UsedVouchersContainer item padding={1.5} container>
          <Typography color="primary" variant="body1">
            <b>
              {!isVoucherCampaign ? campaign.name : voucherCampaignData?.name}
            </b>
          </Typography>
          {!isVoucherCampaign ? (
            <AvailableVoucherContainer>
              <LocalActivityIcon color="primary" />
              <Typography color="black" variant="body1">
                Utilizando vale {usedVouchers + 1} de {givenVouchers}
              </Typography>
            </AvailableVoucherContainer>
          ) : (
            <>
              <AvailableVoucherContainer>
                <VaccinesOutlined color="primary" />
                <Typography color="black" variant="body1">
                  {voucherCampaignData?.product.name}
                </Typography>
              </AvailableVoucherContainer>
              <AvailableVoucherContainer>
                <LocalActivityIcon color="primary" />
                <Typography color="black" variant="body1">
                  {voucherCampaignData?.couponCode}
                </Typography>
              </AvailableVoucherContainer>
            </>
          )}
        </UsedVouchersContainer>
      </Grid>
    </Grid>
  )
}

export default Menu
