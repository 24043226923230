import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { mainTheme } from '../../../styles/mainTheme'
import {
  datePattern,
  formatDocument,
  parseAddress,
  timePattern,
} from '../../../utils/utils'
import { format, isValid } from 'date-fns'
import { IDateRange, IOrder, ShippingPolicy } from '@vacinas-net/shared'
import styled from 'styled-components'
import { useSelector } from '../../../state/store'
import { calculateGivenVouchers, calculateUsedVouchersAmount } from '../utils'

interface ReceiptInfoProps {
  order: IOrder
}

const ReceiptInfoGrid = styled(Grid)`
  padding: ${mainTheme.spacing(2.5)}px;
  gap: ${mainTheme.spacing(1)}px;
  border-radius: 8px;
  background-color: ${mainTheme.palette.primary.light};
`

const ReceiptInfo = (props: ReceiptInfoProps) => {
  const createCampaignOrder = useSelector((state) => state.createCampaignOrder)
  const formData = createCampaignOrder.createOrderForm
  const campaign = useSelector((state) => state.createCampaignOrder.campaign)
  const availableAttendances = useSelector(
    (state) => state.createCampaignOrder.availableAttendances
  )
  const isVoucherCampaign = useSelector(
    (state) => state.createCampaignOrder.isVoucherCampaign
  )
  const voucherCampaignData = useSelector(
    (state) => state.createCampaignOrder.voucherCampaignData
  )
  const availableAttendance = availableAttendances?.find(
    (attendance) => attendance._id === formData.availableAttendanceId
  )
  const shippingPolicy = createCampaignOrder.shippingPolicy
  const vaccinationTime = createCampaignOrder.vaccineShotTime
  const parsedVaccinationTime = vaccinationTime
    ? (JSON.parse(vaccinationTime) as IDateRange)
    : undefined
  const clinicAddress = createCampaignOrder.selectedClinic?.address
  const order = props.order

  const formatDate = (deliveryWindow?: IDateRange) => {
    if (
      !deliveryWindow?.startDate ||
      !deliveryWindow?.endDate ||
      !isValid(deliveryWindow?.startDate) ||
      !isValid(deliveryWindow?.endDate)
    )
      return '-'
    const startDate = format(new Date(deliveryWindow.startDate), datePattern)
    const startTime = format(new Date(deliveryWindow.startDate), timePattern)

    const endTime = format(new Date(deliveryWindow.endDate), timePattern)

    return `${startDate} - ${startTime} até ${endTime}`
  }

  const usedVouchers = campaign ? calculateUsedVouchersAmount(campaign) : 1
  const givenVouchers = campaign ? calculateGivenVouchers(campaign) : 1

  return (
    <ReceiptInfoGrid item xs={12} container>
      <Grid item xs={12} container gap={1}>
        <Typography fontWeight="bold" variant="body1" color="primary">
          Número do pedido:
        </Typography>
        <Typography variant="body1" color="black">
          {order.orderId}
        </Typography>
      </Grid>

      <Grid item xs={12} container gap={1}>
        <Typography fontWeight="bold" variant="body1" color="primary">
          Vale-vacina:
        </Typography>
        <Typography variant="body1" color="black">
          {!isVoucherCampaign
            ? usedVouchers + 1 / givenVouchers
            : voucherCampaignData?.redemptionCode}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ bgcolor: 'primary.main' }} />
      </Grid>

      <Grid item xs={12} container gap={1}>
        <Typography fontWeight="bold" variant="body1" color="primary">
          Nome:
        </Typography>
        <Typography variant="body1" color="black">
          {formData.fullName}
        </Typography>
      </Grid>

      <Grid item xs={12} container gap={1}>
        <Typography fontWeight="bold" variant="body1" color="primary">
          Data de nascimento:
        </Typography>
        <Typography variant="body1" color="black">
          {formData.birthdate
            ? format(new Date(formData.birthdate), datePattern)
            : '-'}
        </Typography>
      </Grid>

      <Grid item xs={12} container gap={1}>
        <Typography fontWeight="bold" variant="body1" color="primary">
          E-mail:
        </Typography>
        <Typography variant="body1" color="black">
          {formData.email}
        </Typography>
      </Grid>

      <Grid item xs={12} container gap={1}>
        <Typography fontWeight="bold" variant="body1" color="primary">
          CPF:
        </Typography>
        <Typography variant="body1" color="black">
          {formatDocument(formData.document)}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ bgcolor: 'primary.main' }} />
      </Grid>

      <Grid item xs={12} container gap={1}>
        <Typography fontWeight="bold" variant="body1" color="primary">
          Data:
        </Typography>
        <Typography variant="body1" color="black">
          {shippingPolicy === ShippingPolicy.Company
            ? formatDate({
                endDate: new Date(String(availableAttendance?.endDatetime)),
                startDate: new Date(String(availableAttendance?.startDatetime)),
              })
            : formatDate({
                endDate: new Date(String(parsedVaccinationTime?.endDate)),
                startDate: new Date(String(parsedVaccinationTime?.startDate)),
              })}
        </Typography>
      </Grid>

      <Grid item xs={12} container gap={1}>
        <Typography fontWeight="bold" variant="body1" color="primary">
          Endereço:
        </Typography>
        <Typography variant="body1" color="black">
          {shippingPolicy === ShippingPolicy.Company
            ? parseAddress(availableAttendance?.address)
            : parseAddress(clinicAddress)}
        </Typography>
      </Grid>
    </ReceiptInfoGrid>
  )
}

export default ReceiptInfo
