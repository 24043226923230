import { Button, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import React, { useState } from 'react'
import ReactInputMask from 'react-input-mask'
import { StyledTextFieldSquare } from '../../utils/sharedStyles'
import { actions } from '../../state/createCampaignOrder'
import { useSelector } from '../../state/store'
import { cpf } from 'cpf-cnpj-validator'
import { LoadingButton } from '@mui/lab'
import { useNavigate } from 'react-router-dom'
import {
  CreateCampaignOrderContent,
  CreateCampaignOrderLayout,
  TitleGrid,
  InputGrid,
  SubmitGrid,
} from './styles'

import {
  getCampaignAllowerUserList,
  getCampaignByVoucherCode,
} from '../../services/createCampaignOrder'
import { errorActions } from '../../state/errors'
import CreateCampaignOrderFooter from './CreateCampaignOrderFooter'

const SearchCampaignStep = () => {
  const [errors, setErrors] = useState({
    document: '',
    voucher: '',
  })

  const customer = useSelector((state) => state.createCampaignOrder.customer)
  const isLoading = useSelector((state) => state.createCampaignOrder.isLoading)
  const isVoucherCampaign = useSelector(
    (state) => state.createCampaignOrder.isVoucherCampaign
  )
  const voucherCampaignData = useSelector(
    (state) => state.createCampaignOrder.voucherCampaignData
  )
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const validateVoucher = () => {
    const regex = /^[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}$/
    const isValidVoucher = regex.test(voucherCampaignData!.redemptionCode!)

    setErrors((prevErrors) => ({
      ...prevErrors,
      voucher: isValidVoucher ? '' : 'Código do vale-presente inválido',
    }))

    return isValidVoucher
  }

  const validateCustomer = () => {
    const hasValidDocument = cpf.isValid(customer.document)

    setErrors({
      document: hasValidDocument ? '' : 'CPF inválido',
      voucher: '',
    })

    return hasValidDocument
  }

  const searchCampaigns = async () => {
    let validation = false

    !isVoucherCampaign
      ? (validation = validateCustomer())
      : (validation = validateVoucher())

    if (!validation) return

    dispatch(actions.setLoading(true))

    try {
      if (!isVoucherCampaign) {
        const campaigns = await getCampaignAllowerUserList({
          document: customer.document.replace(/[^\d]/g, ''),
        })

        if (!campaigns?.length) return navigate('/campaign/order/not-found')

        dispatch(actions.setCampaigns(campaigns))
        navigate('/campaign/order/list')
      } else {
        const voucherCampaign = await getCampaignByVoucherCode(
          voucherCampaignData!.redemptionCode!
        )

        if (!voucherCampaign) return navigate('/campaign/order/not-found')

        dispatch(
          actions.setVoucherCampaignData({
            _id: voucherCampaign._id,
            name: voucherCampaign.name,
            dateRange: voucherCampaign.dateRange,
            couponCode: voucherCampaign.coupons[0],
            productPriceInCents: voucherCampaign.productPriceInCents,
            product: {
              ...voucherCampaign.fullCommercialConditions[0].products[0],
              category: 'vaccine',
            },
            voucherId: voucherCampaign.vouchers[0]._id,
          })
        )
        navigate('/campaign/order/schedule')
      }
    } catch (error) {
      const errorMessage = (error as Error).message

      dispatch(
        errorActions.setError({
          errorId: String(Date.now()),
          errorMessage,
        })
      )
    } finally {
      dispatch(actions.setLoading(false))
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setCustomerValue = (key: string, value: any) => {
    dispatch(actions.setCustomer({ [key]: value }))
    setErrors({
      ...errors,
      [key]: '',
    })
  }

  const setVoucherCode = (redemptionCode: string) => {
    dispatch(actions.setVoucherCampaignData({ redemptionCode }))
    setErrors({
      ...errors,
      voucher: '',
    })
  }

  return (
    <CreateCampaignOrderLayout>
      <CreateCampaignOrderContent container xs={12}>
        <TitleGrid item>
          <Typography color="primary" variant={'h1'}>
            É um prazer ter você aqui!
          </Typography>
          <Typography marginTop={1} color="black" variant={'body1'}>
            Para agendar sua vacinação precisamos de algumas informações.
            Preencha os campos abaixo e vamos começar.
          </Typography>
        </TitleGrid>
        <InputGrid item>
          {isVoucherCampaign ? (
            <div>
              <ReactInputMask
                mask="aaaa-aaaa-aaaa-aaaa"
                disabled={false}
                value={voucherCampaignData?.redemptionCode}
                onChange={(event) => {
                  setVoucherCode(event.target.value)
                }}
              >
                {() => (
                  <StyledTextFieldSquare
                    color="primary"
                    label="Vale-presente"
                    size="small"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: Boolean(voucherCampaignData?.redemptionCode),
                    }}
                    error={Boolean(errors.voucher)}
                  />
                )}
              </ReactInputMask>
              <Typography color="error" variant="body2" marginTop={0.5}>
                {errors.voucher}
              </Typography>
            </div>
          ) : (
            <div>
              <ReactInputMask
                mask="999.999.999-99"
                disabled={false}
                value={customer.document}
                onChange={(event) => {
                  setCustomerValue('document', event.target.value)
                }}
              >
                {() => (
                  <StyledTextFieldSquare
                    color="primary"
                    label="CPF"
                    size="small"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: Boolean(customer.document) }}
                    error={Boolean(errors.document)}
                  />
                )}
              </ReactInputMask>
              <Typography color="error" variant="body2" marginTop={0.5}>
                {errors.document}
              </Typography>
            </div>
          )}
          <Button
            variant="text"
            onClick={() =>
              dispatch(actions.setIsVoucherCampaign(!isVoucherCampaign))
            }
          >
            {!isVoucherCampaign ? 'Tenho um vale-presente' : 'Usar CPF'}
          </Button>
        </InputGrid>
        <SubmitGrid item>
          <LoadingButton
            loading={isLoading}
            disabled={isLoading || !!errors.document}
            onClick={() => searchCampaigns()}
            variant="contained"
            size="large"
          >
            {!isVoucherCampaign ? 'Buscar campanhas' : 'Iniciar agendamento'}
          </LoadingButton>
        </SubmitGrid>
      </CreateCampaignOrderContent>
      <CreateCampaignOrderFooter />
    </CreateCampaignOrderLayout>
  )
}

export default SearchCampaignStep
