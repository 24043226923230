import React, { useEffect } from 'react'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import {
  ScheduleOrderFormContainer,
  ScheduleOrderMenuContainer,
} from '../ScheduleOrderStep/styles'
import Menu from './Menu'
import {
  CreateCampaignOrderFooterStyled,
  ScheduleOrderFormGrid,
  HighlightTypography,
} from './styles'
import ReceiptInfo from './ReceiptInfo'
import { useNavigate } from 'react-router-dom'
import { useSelector } from '../../../state/store'
import { useDispatch } from 'react-redux'
import { actions } from '../../../state/createCampaignOrder'
import { LoadingButton } from '@mui/lab'
import { getCampaignAllowerUserList } from '../../../services/createCampaignOrder'
import { errorActions } from '../../../state/errors'
import { IOrder } from '@vacinas-net/shared'

const ScheduleOrderReceipt = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const customer = useSelector((state) => state.createCampaignOrder.customer)
  const campaign = useSelector((state) => state.createCampaignOrder.campaign)
  const order = useSelector(
    (state) => state.createCampaignOrder.createdOrder
  ) as IOrder
  const isLoading = useSelector((state) => state.createCampaignOrder.isLoading)
  const isVoucherCampaign = useSelector(
    (state) => state.createCampaignOrder.isVoucherCampaign
  )

  useEffect(() => {
    if (!isVoucherCampaign && (!campaign || !order)) navigate('/campaign/order')
  }, [])

  const searchCampaigns = async () => {
    try {
      if (!isVoucherCampaign) {
        dispatch(actions.setLoading(true))
        const campaigns = await getCampaignAllowerUserList({
          document: customer.document.replace(/[^\d]/g, ''),
        })

        if (!campaigns?.length) return navigate('/campaign/order/not-found')

        dispatch(actions.setCampaigns(campaigns))

        navigate('/campaign/order/list')
      } else {
        return navigate('/campaign/order')
      }
    } catch (error) {
      const errorMessage = (error as Error).message

      dispatch(
        errorActions.setError({
          errorId: String(Date.now()),
          errorMessage,
        })
      )
    } finally {
      dispatch(actions.setLoading(false))
    }
  }

  const submit = async () => {
    dispatch(
      actions.setCreateOrderFormOrder({
        fullName: '',
        birthdate: '',
        email: '',
        document: '',
        phone: '',
        placeName: '',
        availableAttendanceId: '',
      })
    )
    dispatch(actions.setSearchedZipCode(''))
    dispatch(actions.setAvailableAttendances(undefined))
    dispatch(actions.setSearchedClinicsByZipCode(undefined))
    dispatch(actions.setVaccineShotTime(undefined))
    dispatch(actions.setVaccineShotDay(undefined))
    dispatch(actions.setSelectedClinic(undefined))
    dispatch(actions.setSearchedZipCode(undefined))
    dispatch(actions.setCreateOrderFormStep('form'))
    dispatch(actions.setCampaign(undefined))
    dispatch(actions.setIsVoucherCampaign(false))
    dispatch(
      actions.setVoucherCampaignData({
        name: '',
        productPriceInCents: undefined,
        _id: '',
        product: {
          skuId: '',
          category: 'vaccine',
          name: '',
        },
        couponCode: '',
        redemptionCode: '',
        voucherId: '',
      })
    )

    await searchCampaigns()
  }

  if (!isVoucherCampaign && (!campaign || !order)) return <></>

  return (
    <Grid container style={{ minHeight: '100vh', backgroundColor: 'white' }}>
      <ScheduleOrderMenuContainer item xs={12} md={4} lg={3}>
        <CreateCampaignOrderFooterStyled />
        <Menu />
      </ScheduleOrderMenuContainer>
      <ScheduleOrderFormContainer item xs={12} md={8} lg={9}>
        <ScheduleOrderFormGrid container>
          <Grid item xs={12}>
            <Typography color="primary" variant="h1" marginBottom={1.5}>
              Agendamento feito com sucesso!
            </Typography>
            <Typography variant="h2" fontWeight={'normal'}>
              Agora basta{' '}
              <HighlightTypography>informar o CPF</HighlightTypography> para o
              profissional que realizará a aplicação.{' '}
              <HighlightTypography>
                Leve um documento com foto!
              </HighlightTypography>
            </Typography>
          </Grid>

          <ReceiptInfo order={order} />

          <Grid item xs={12} marginBottom={4.5}>
            <LoadingButton
              loading={isLoading}
              disabled={isLoading}
              variant="contained"
              onClick={() => submit()}
              fullWidth
            >
              RETORNAR PARA PÁGINA INICIAL
            </LoadingButton>
          </Grid>
        </ScheduleOrderFormGrid>
      </ScheduleOrderFormContainer>
    </Grid>
  )
}

export default ScheduleOrderReceipt
