import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  StyledSelectSquare,
  StyledTextFieldSquare,
} from '../../../../utils/sharedStyles'
import ReactInputMask from 'react-input-mask'
import { mainTheme } from '../../../../styles/mainTheme'
import { ClinicVaccinationDesktopContainer } from '../styles'
import SelectClinicModal from './SelectClinicModal'
import { LocationOn } from '@mui/icons-material'
import { datePattern, parseAddress, timePattern } from '../../../../utils/utils'
import { useSelector } from '../../../../state/store'
import { getClinicOpeningHours } from '../../../../services/createCampaignOrder'
import { IDateRange } from '@vacinas-net/shared'
import { toast } from 'react-toastify'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { format, isSameDay } from 'date-fns'
import { useDispatch } from 'react-redux'
import { actions } from '../../../../state/createCampaignOrder'

const SelectClinic = () => {
  const dispatch = useDispatch()
  const zipCode = useSelector(
    (state) => state.createCampaignOrder.searchedZipCode
  )
  const [open, setOpen] = useState(false)
  const errors = useSelector(
    (state) => state.createCampaignOrder.placeStepErrors
  )

  // eslint-disable-next-line prettier/prettier
  const [clinicDateRange, setClinicDateRange] = useState<
    IDateRange[] | undefined
  >()

  const searchedClinicsByZipCode = useSelector(
    (state) => state.createCampaignOrder.searchedClinicsByZipCode
  )
  const selectedClinic = useSelector(
    (state) => state.createCampaignOrder.selectedClinic
  )

  const vaccineShotDay = useSelector(
    (state) => state.createCampaignOrder.vaccineShotDay
  )

  const vaccineShotTime = useSelector(
    (state) => state.createCampaignOrder.vaccineShotTime
  )

  const placeStepErrors = useSelector(
    (state) => state.createCampaignOrder.placeStepErrors
  )

  const isVoucherCampaign = useSelector(
    (state) => state.createCampaignOrder.isVoucherCampaign
  )
  const voucherCampaignData = useSelector(
    (state) => state.createCampaignOrder.voucherCampaignData
  )

  const campaign = useSelector((state) => state.createCampaignOrder.campaign)

  const getSelectedDayAvailableHours = () => {
    if (!clinicDateRange || !vaccineShotDay) {
      return []
    }
    const selectedDayAvailableHours = clinicDateRange?.filter((dateRange) => {
      if (isSameDay(new Date(dateRange.startDate!), new Date(vaccineShotDay))) {
        return dateRange
      }
    })
    return selectedDayAvailableHours
  }

  const parseDateRange = (dateRange: IDateRange) => {
    if (!dateRange.startDate || !dateRange.endDate) {
      return '-'
    }
    return `${format(new Date(dateRange.startDate), timePattern)} até ${format(
      new Date(dateRange.endDate),
      timePattern
    )}`
  }

  const fetchClinicOpeningHours = async () => {
    const payload = {
      clinicId: String(selectedClinic?._id),
      campaignId: !isVoucherCampaign
        ? String(campaign?._id)
        : String(voucherCampaignData?._id),
    }

    try {
      const response = await getClinicOpeningHours(payload)
      setClinicDateRange(response)
      dispatch(actions.setVaccineShotDay(null))
    } catch (error) {
      console.error(error)
      toast(String(error), { type: 'error' })
    }
  }

  useEffect(() => {
    if (selectedClinic) {
      fetchClinicOpeningHours()
    }
  }, [selectedClinic])

  useEffect(() => {
    dispatch(actions.setVaccineShotTime(undefined))
  }, [vaccineShotDay])

  return (
    <ClinicVaccinationDesktopContainer
      marginTop={1}
      spacing={2}
      marginBottom={1}
      container
      xs={12}
      width={726}
    >
      <Grid item xs={6}>
        <ReactInputMask
          mask="99999-999"
          disabled={false}
          value={zipCode}
          onChange={(event) => {
            dispatch(actions.setSearchedZipCode(event.target.value))
            dispatch(actions.setSelectedClinic(undefined))
            dispatch(actions.setVaccineShotDay(undefined))
            dispatch(actions.setVaccineShotTime(undefined))
          }}
        >
          {() => (
            <StyledTextFieldSquare
              color="primary"
              label="CEP"
              size="small"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: Boolean(zipCode) }}
            />
          )}
        </ReactInputMask>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={6}>
          <Button
            disabled={
              !searchedClinicsByZipCode || searchedClinicsByZipCode?.length < 1
            }
            onClick={() => {
              setOpen(true)
            }}
            color="secondary"
            variant="contained"
            size="medium"
          >
            Escolher clínica
          </Button>
          <Typography color="error" variant="body2" marginTop={0.5}>
            {errors.selectedClinic}
          </Typography>
          {zipCode &&
            (!searchedClinicsByZipCode ||
              searchedClinicsByZipCode?.length < 1) && (
              <Typography variant="body2" marginTop={1} marginBottom={5}>
                Não encontrou clínicas perto de seu CEP?
                <Link
                  target="_blank"
                  href={`https://api.whatsapp.com/send?phone=5511933930901&text=Olá! Não encontrei clínicas próximas de meu CEP ${zipCode} ao agendar para a campanha ${
                    !isVoucherCampaign
                      ? campaign?.name
                      : voucherCampaignData?.name
                  }.`}
                >
                  <br /> Contatar o suporte
                </Link>
              </Typography>
            )}
        </Grid>
      </Grid>
      {selectedClinic && (
        <Grid item xs={12}>
          <Typography color="primary" variant="h2">
            Local Escolhido
          </Typography>
          <Typography
            style={{ marginTop: mainTheme.spacing(2) }}
            color="secondary"
            variant="h3"
          >
            {selectedClinic.name}
          </Typography>
          <Grid marginTop={2} container xs={12}>
            <LocationOn color="primary" />
            <Typography marginLeft={0.3} variant="body1" color="textSecondary">
              Endereço:
            </Typography>
            <Typography marginLeft={0.6} variant="body1" color="primary">
              {parseAddress(selectedClinic.address)}
            </Typography>
          </Grid>

          {clinicDateRange?.length && clinicDateRange?.length > 0 && (
            <Grid marginTop={2} spacing={2} container xs={12}>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    shouldDisableDate={(date) => {
                      if (
                        !clinicDateRange.find((dateRange) =>
                          isSameDay(
                            new Date(String(dateRange.startDate)),
                            new Date(String(date))
                          )
                        )
                      ) {
                        return true
                      }
                      return false
                    }}
                    inputFormat={datePattern}
                    renderInput={(props) => (
                      <StyledTextFieldSquare
                        {...props}
                        size="small"
                        fullWidth
                        label="Data de Vacinação*"
                      />
                    )}
                    onChange={(value) => {
                      dispatch(actions.setVaccineShotDay(String(value)))
                      dispatch(
                        actions.setPlaceStepErrors({
                          ...placeStepErrors,
                          vaccineShotDay: '',
                        })
                      )
                    }}
                    value={vaccineShotDay || null}
                    openTo="day"
                    views={['year', 'month', 'day']}
                  />
                </LocalizationProvider>
                <Typography color="error" variant="body2" marginTop={0.5}>
                  {errors.vaccineShotDay}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth size="small">
                  <InputLabel>Horário*</InputLabel>
                  <StyledSelectSquare
                    disabled={getSelectedDayAvailableHours().length === 0}
                    value={vaccineShotTime}
                    onChange={(e) => {
                      dispatch(
                        actions.setVaccineShotTime(String(e.target.value))
                      )
                      dispatch(
                        actions.setPlaceStepErrors({
                          ...placeStepErrors,
                          vaccineShotTime: '',
                        })
                      )
                    }}
                    label="Horário"
                  >
                    {getSelectedDayAvailableHours().map((item, index) => (
                      <MenuItem key={index} value={JSON.stringify(item)}>
                        {parseDateRange(item)}
                      </MenuItem>
                    ))}
                  </StyledSelectSquare>
                  <Typography color="error" variant="body2" marginTop={0.5}>
                    {errors.vaccineShotTime}
                  </Typography>
                </FormControl>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}

      <SelectClinicModal
        open={open}
        setOpen={(state: boolean) => {
          setOpen(state)
        }}
      />
    </ClinicVaccinationDesktopContainer>
  )
}

export default SelectClinic
