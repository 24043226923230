import React, { useEffect, useState } from 'react'
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material'
import { StyledTextFieldSquare } from '../../../../utils/sharedStyles'
import ReactInputMask from 'react-input-mask'
import { useSelector } from '../../../../state/store'
import first from 'lodash/first'
import { useDispatch } from 'react-redux'
import {
  actions,
  CreateCampaignOrderStateOrder,
} from '../../../../state/createCampaignOrder'
import styled from 'styled-components'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { datePattern } from '../../../../utils/utils'

const CustomCheckbox = styled(Checkbox)`
  padding-top: 0;
  padding-bottom: 0;
`

const PersonalDataForm = () => {
  const dispatch = useDispatch()
  const campaign = useSelector((state) => state.createCampaignOrder.campaign)
  const errors = useSelector(
    (state) => state.createCampaignOrder.createOrderFormErrors
  )
  const formData = useSelector(
    (state) => state.createCampaignOrder.createOrderForm
  )
  const allowedCustomersBatch = first(campaign?.allowedCustomersBatches)
  const allowedCustomer = first(allowedCustomersBatch?.allowedCustomers)

  const [schedulingForMyself, setSchedulingForMyself] = useState(false)

  const setOrderValue = (params: Partial<CreateCampaignOrderStateOrder>) => {
    const newErrors: { [key: string]: string } = {}
    const keys = Object.keys(params)
    keys.forEach((key) => {
      newErrors[key] = ''
    })
    dispatch(actions.setCreateOrderFormErrors(newErrors))
    dispatch(actions.setCreateOrderFormOrder(params))
  }

  const isVoucherCampaign = useSelector(
    (state) => state.createCampaignOrder.isVoucherCampaign
  )

  useEffect(() => {
    if (!schedulingForMyself || !allowedCustomer) return

    setOrderValue({
      document: allowedCustomer.document,
      fullName: allowedCustomer.fullName || formData.fullName,
      email: allowedCustomer.email || formData.email,
    })
  }, [schedulingForMyself])

  return (
    <Grid item xs={12}>
      <Typography marginBottom={1.5} color="primary" variant="h2">
        Dados pessoais
      </Typography>

      <Grid container spacing={2}>
        {!isVoucherCampaign ? (
          <Grid item xs={12} marginBottom={0.5}>
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={schedulingForMyself}
                  onChange={(event) =>
                    setSchedulingForMyself(event.target.checked)
                  }
                />
              }
              label="Estou agendando para mim mesmo"
            />
          </Grid>
        ) : (
          ''
        )}
        <Grid item xs={12} md={8}>
          <StyledTextFieldSquare
            disabled={schedulingForMyself && !!allowedCustomer?.fullName}
            size="small"
            label="Nome completo*"
            fullWidth
            value={formData.fullName}
            onChange={(event) =>
              setOrderValue({ fullName: event.target.value })
            }
            error={!!errors.fullName}
          />
          <Typography color="error" variant="body2" marginTop={0.5}>
            {errors.fullName}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              inputFormat={datePattern}
              renderInput={(props) => (
                <StyledTextFieldSquare
                  {...props}
                  size="small"
                  fullWidth
                  label="Data de Nascimento*"
                  error={!!errors.birthdate}
                />
              )}
              disableFuture
              onChange={(value) =>
                setOrderValue({ birthdate: value ?? undefined })
              }
              value={formData.birthdate}
              openTo="year"
              views={['year', 'month', 'day']}
            />
          </LocalizationProvider>
          <Typography color="error" variant="body2" marginTop={0.5}>
            {errors.birthdate}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledTextFieldSquare
            disabled={schedulingForMyself && !!allowedCustomer?.email}
            size="small"
            label="E-mail*"
            fullWidth
            value={formData.email}
            onChange={(event) => setOrderValue({ email: event.target.value })}
            error={!!errors.email}
          />
          <Typography color="error" variant="body2" marginTop={0.5}>
            {errors.email}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <ReactInputMask
            mask="999.999.999-99"
            value={formData.document}
            onChange={(event) =>
              setOrderValue({ document: event.target.value })
            }
            disabled={schedulingForMyself && !!allowedCustomer?.document}
          >
            {() => (
              <StyledTextFieldSquare
                color="primary"
                label="CPF*"
                size="small"
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: Boolean(formData.document) }}
                error={!!errors.document}
                disabled={schedulingForMyself && !!allowedCustomer?.document}
              />
            )}
          </ReactInputMask>
          <Typography color="error" variant="body2" marginTop={0.5}>
            {errors.document}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <ReactInputMask
            mask="(99) 99999-9999"
            disabled={false}
            value={formData.phone}
            onChange={(event) => setOrderValue({ phone: event.target.value })}
          >
            {() => (
              <StyledTextFieldSquare
                color="primary"
                label="Telefone*"
                size="small"
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: Boolean(formData.phone) }}
                error={!!errors.phone}
              />
            )}
          </ReactInputMask>
          <Typography color="error" variant="body2" marginTop={0.5}>
            {errors.phone}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PersonalDataForm
