import React from 'react'
import Grid from '@mui/material/Grid'
import { useSelector } from '../../../../state/store'
import { Button } from '@mui/material'
import { actions } from '../../../../state/createCampaignOrder'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  createCampaignOrder,
  CreateCampaignOrderPayload,
  createVoucherOrder,
} from '../../../../services/createCampaignOrder'
import {
  CreateCampaignOrderDTO,
  IDateRange,
  IOrder,
  ShippingPolicy,
} from '@vacinas-net/shared'
import { LoadingButton } from '@mui/lab'
import { errorActions } from '../../../../state/errors'
import * as uuid from 'uuid'

const SubmitSection = () => {
  const formData = useSelector((state) => state.createCampaignOrder)
  const { createOrderForm, voucherCampaignData, isVoucherCampaign } = formData
  const customer = useSelector((state) => state.createCampaignOrder.customer)
  const isLoading = useSelector((state) => state.createCampaignOrder.isLoading)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const submit = async () => {
    dispatch(actions.setLoading(true))
    try {
      let result = {}
      if (!isVoucherCampaign) {
        const payload: CreateCampaignOrderDTO = {
          campaignId: String(formData.campaign?._id),
          shippingPolicy:
            formData.shippingPolicy === ShippingPolicy.Company
              ? ShippingPolicy.Company
              : ShippingPolicy.Clinic,
          allowedCustomerDocument: customer.document.replace(/[^\d]/g, ''),
          availableAttendanceId:
            formData.shippingPolicy === ShippingPolicy.Company
              ? createOrderForm.availableAttendanceId
              : undefined,
          customer: {
            birthDate: createOrderForm.birthdate,
            document: createOrderForm.document.replace(/[^\d]/g, ''),
            email: createOrderForm.email,
            name: createOrderForm.fullName,
            phone: createOrderForm.phone.replace(/[^\d]/g, ''),
          },
          clinicId:
            formData.shippingPolicy === ShippingPolicy.Clinic
              ? formData.selectedClinic?._id
              : undefined,
          deliveryWindow:
            formData.shippingPolicy === ShippingPolicy.Clinic
              ? (JSON.parse(formData.vaccineShotTime!) as Required<IDateRange>)
              : undefined,
        }
        result = await createCampaignOrder(
          payload as CreateCampaignOrderPayload
        )
      } else {
        const orderId = `J-${Date.now()}`
        const payload: Partial<IOrder> = {
          orderId,
          isCreatedManually: true,
          purchasedAt: new Date(),
          customer: {
            name: createOrderForm.fullName,
            document: createOrderForm.document.replace(/[^\d]/g, ''),
            email: createOrderForm.email,
            phone: createOrderForm.phone.replace(/[^\d]/g, ''),
          },
          items: [
            {
              orderId,
              clinicId: formData.selectedClinic?._id,
              product: voucherCampaignData?.product,
              appliedCouponCode: voucherCampaignData?.couponCode,
              quantity: 1,
              priceInCents: voucherCampaignData?.productPriceInCents,
              discountInCents: 0,
            },
          ],
          status: 'awaiting_clinic_confirmation',
          shippingAddress: formData.selectedClinic?.address,
          payments: [
            {
              paymentId: uuid.v4(),
              method: 'voucher',
              status: '',
              referencePriceInCents: voucherCampaignData?.productPriceInCents,
              chargedPriceInCents: voucherCampaignData?.productPriceInCents,
              installmentCount: 1,
              taxes: {
                totalValueInCents: 0,
              },
              voucher: {
                voucherId: voucherCampaignData?.voucherId,
                redemptionCode: voucherCampaignData?.redemptionCode,
                valueInCents: voucherCampaignData?.productPriceInCents,
              },
            },
          ],
          marketingData: {
            couponCode: voucherCampaignData?.couponCode,
          },
          packages: [
            {
              clinicId: formData.selectedClinic?._id,
              productList: [
                {
                  skuId: voucherCampaignData?.product.skuId,
                  quantity: 1,
                },
              ],
              type: 'pickup',
              pickupPointName: formData.selectedClinic?.name,
              pickupPointAddress: formData.selectedClinic?.address,
              deliveryWindow:
                formData.shippingPolicy === ShippingPolicy.Clinic
                  ? (JSON.parse(
                      formData.vaccineShotTime!
                    ) as Required<IDateRange>)
                  : undefined,
              shippingPriceInCents: 0,
            },
          ],
        }
        result = await createVoucherOrder(payload)
      }
      dispatch(actions.setCreatedOrder(result))
      navigate('/campaign/order/receipt')
    } catch (error) {
      const errorMessage = (error as Error).message

      dispatch(
        errorActions.setError({
          errorId: String(Date.now()),
          errorMessage,
        })
      )
    } finally {
      dispatch(actions.setLoading(false))
    }
  }

  const goBack = () => {
    dispatch(actions.setCreateOrderFormStep('place'))
  }

  return (
    <Grid
      container
      item
      xs={12}
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
      marginBottom={4.5}
      gap={2}
    >
      <Button onClick={() => goBack()} variant="text" size="small">
        {'< Alterar dados'}
      </Button>
      <LoadingButton
        loading={isLoading}
        disabled={isLoading}
        onClick={() => submit()}
        variant="contained"
        size="large"
      >
        Revisar e Enviar
      </LoadingButton>
    </Grid>
  )
}

export default SubmitSection
